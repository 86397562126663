<template>
  <div class="view-wrapper align-center overflow-y-auto">
    <v-card class="px-2 ma-2 mt-12 elevation-5" style="width: 350px;">
      <v-card-title class="justify-center">
        {{ $localize('registration') }}
      </v-card-title>
      <v-card-text class="pl-2 pr-2 pb-0" @keydown.enter="signUp">
        <v-text-field v-model="item.firstname"
                      :rules="[rules.required]"
                      :label= "$localize('first_name')"
                      outlined
                      clearable
                      dense
        ></v-text-field>
        <v-text-field v-model="item.lastname"
                      :rules="[rules.required]"
                      :label= "$localize('last_name')"
                      outlined
                      clearable
                      dense
        ></v-text-field>
        <v-text-field v-model="item.middlename"
                      :rules="[rules.required]"
                      :label= "$localize('middle_name')"
                      outlined
                      clearable
                      dense
        ></v-text-field>
        <v-text-field v-model="item.phone"
                      :rules="[rules.required]"
                      :label= "$localize('telephone_number')"
                      prepend-inner-icon="mdi-phone"
                      return-masked-value
                      v-mask="'+7(###)-###-##-##'"
                      outlined
                      clearable
                      dense
        ></v-text-field>
        <v-text-field v-model="item.email"
                      :rules="[rules.required, rules.email]"
                      :label= "$localize('email')"
                      outlined
                      clearable
                      dense
        ></v-text-field>
        <v-text-field v-model="item.password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required, rules.password]"
                      :type="showPassword ? 'text' : 'password'"
                      :label= "$localize('password')"
                      outlined
                      clearable
                      dense
                      @click:append="showPassword = !showPassword"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="mt-0 pt-0 mb-7">
        <v-row class="justify-center" justify="space-around">
          <v-btn dark
                 color="success"
                 text
                 small
                 @click="$router.push('/home').catch(() => {});"
          >
            {{ $localize('already_have_an_account?') }}
          </v-btn>
          <v-btn dark
                 color="success"
                 :outlined="!isFormFilled"
                 @click="signUp"
          >
            <span class="pl-4 pr-4">{{$localize('registration')}}</span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// const phoneRegex = new RegExp("/\\d/");

export default {
  data(){
    return{
      item: {
        lastname: undefined,
        firstname: undefined,
        middlename: undefined,
        phone: undefined,
        email: undefined,
        password: undefined,
      },
      showPassword: false,
      agreement: false,
      isButtonRegisterDisabled: false,

      rules: {
        required: value => !!value || this.$localize('mandatory_field'),
        email: value => {
          return this.$testRegex('email', value) || this.$localize('enter_email')
        },
        password: value => {
          return this.$testRegex('strongPassword', value) || this.$localize('password_should_be_not_less_than_8_symbols_and_contains_a_digit_and_letter_in_upper_and_lower_case')
        },
      },
    }
  },

  computed: {
    isFormFilled() {
      for (let key in this.item) {
        if (!this.item[key]) return false;
      }
      if(this.isButtonRegisterDisabled) return false
      if (this.item.email && !this.$testRegex('email',this.item.email)) return false;
      else if (this.item.password && !this.$testRegex('strongPassword', this.item.password)) return false;
      else if (this.item.phone && this.item.phone.length < 17) return false;
      return true;
    }
  },

  methods: {
    async signUp() {
      if (this.isFormFilled) {
        this.isButtonRegisterDisabled = true
        let cloned = this.$lodash.clone(this.item)
        cloned.phone = cloned.phone.replace(/[-+()]/g, '').substring(1)
        this.$appUtils.setOverlay(true)
        const res = await this.$fetch.post('api/auth/sign-up', this.$vm2js(cloned), true)
        this.$appUtils.setOverlay(false)
        if ( res && res.status === 'ACCESS') {
          this.$store.dispatch('setError', {type: 'success', message: 'register_successfully'})
          this.$router.push('/home');
        } else {
          this.$store.dispatch('setError', {type: 'error', message: 'error_try_again_later'})
          this.isButtonRegisterDisabled = false
        }
      } else if(!this.isButtonRegisterDisabled){
        this.$store.dispatch('setError', {type: 'warning', message: 'localize'})
      }
    },
  }
}
</script>

<style>

</style>
